import { useApolloClient } from "@apollo/client"
import { useEffect, useMemo, useState } from "react"
import useQuery from "../../hooks/useQuery"
import CLEAR_REPORTS from "./graphql/clearReports.graphql"
import GET_COMMENTS from "./graphql/getComments.graphql"
import GET_NB_COMMENTS from "./graphql/getCommentsCount.graphql"
import PUBLISH_COMMENT from "./graphql/publishComment.graphql"
import PUBLISH_COMMENTS from "./graphql/publishComments.graphql"
import REJECT_COMMENT from "./graphql/rejectComment.graphql"
import UPDATE_COMMENT from "./graphql/updateComment.graphql"
import { useFilterState } from "./state/FiltersState"

export default () => {
  const client = useApolloClient()
  const { filters, limit } = useFilterState()

  const [comments, setComments] = useState([])

  // Load the number of comments (updates whenever filters change)
  const [nbCommentsLoading, nbComments] = useQuery(
    {
      query: GET_NB_COMMENTS,
      variables: {
        filters,
      },
      fetchPolicy: "network-only",
      formatResult: data => {
        return data.tutoring.counts.comments
      },
    },
    [filters]
  )

  const [loading, fetchedComments, , refetch] = useQuery(
    {
      query: GET_COMMENTS,
      variables: {
        skip: 0,
        filters,
        limit,
      },
      fetchPolicy: "network-only",
      formatResult: data => {
        return data.tutoring.comments
      },
    },
    [filters, limit]
  )

  // Check if there's more comments to load
  const hasMore = useMemo(() => {
    if (!fetchedComments) return true
    return fetchedComments.length === limit
  }, [fetchedComments, limit])

  // Fetch more comments when there are more
  useEffect(() => {
    if (comments.length === 0 && hasMore) {
      refetch()
    }
    // eslint-disable-next-line
  }, [comments, hasMore])

  useEffect(() => {
    if (fetchedComments) {
      setComments([...fetchedComments])
    }
  }, [fetchedComments])

  const removeCommentFromList = _id => {
    setComments(comments.filter(c => c._id !== _id))
  }

  const publishComment = async ({ _id }) => {
    const { errors } = await client.mutate({
      mutation: PUBLISH_COMMENT,
      variables: { commentId: _id },
    })
    if (!errors) {
      removeCommentFromList(_id)
    }
  }

  const updateComment = async ({ _id, text }) => {
    const { data, errors } = await client.mutate({
      mutation: UPDATE_COMMENT,
      variables: { comment: { _id, text } },
    })
    if (!errors) {
      setComments(
        comments.map(c =>
          c._id !== _id ? { ...c } : { ...c, ...data.updateComment }
        )
      )
    }
  }

  const rejectComment = async ({ _id }) => {
    const { errors } = await client.mutate({
      mutation: REJECT_COMMENT,
      variables: { commentId: _id },
    })
    if (!errors) {
      removeCommentFromList(_id)
    }
  }

  const publishAll = async () => {
    const { errors } = await client.mutate({
      mutation: PUBLISH_COMMENTS,
      variables: { commentIds: comments.map(c => c._id) },
    })
    if (!errors) {
      setComments([])
    }
  }

  const clearReports = async comment => {
    const { data } = await client.mutate({
      mutation: CLEAR_REPORTS,
      variables: {
        commentId: comment._id,
      },
    })
    if (data.clearReports) {
      setComments(
        [...comments].map(d =>
          d._id !== comment._id ? d : { ...d, reports: [] }
        )
      )
    }
  }

  return {
    comments,
    loading,
    publishComment,
    publishAll,
    rejectComment,
    updateComment,
    nbCommentsLoading,
    nbComments,
    clearReports,
  }
}
