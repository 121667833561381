import React from "react"
import  { Avatar } from "nzk-react-components"
import { Link } from "gatsby"
import styled from 'styled-components'
import { colors } from "../../../../theme"

interface UserProps {
  username?: string
  name?: string
  type?: "student" | "parent" | "teacher" | "tutor"
  countryCode?: string
  avatar?: {
    url?: string
  }
}

const Wrapper = styled.div`
  background-color: rgba(255,255,255,0.15);
  padding: 5px 8px;
  border-radius: 10px;
  color: #fff;
  font-weight: 500;
  display: flex;
  align-items: center;
`

const Name = styled.div`
  margin-left: 8px;
`

const User = (props: UserProps) => {
  const { username, name } = props
  return username ? (
    <Link to={`/student/${username}`}>
      <Wrapper>
        { /* @ts-ignore */ }
        <Avatar user={props} size='40px' />
        <Name>{username || name}</Name>
      </Wrapper>
    </Link>
  ) : (
    <Wrapper>
      { /* @ts-ignore */ }
      <Avatar user={props} size='40px' />
      <Name>{username || name}</Name>
    </Wrapper>
  )
}

export default User
