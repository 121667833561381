import React, { useState } from "react"
import { createContainer } from "unstated-next"

interface Filters {
  statusIn: string[],
  wordCount?: { from?: number, to?: number }
  nbReports?: { from?: number, to?: number }
  createdAt?: { from?: Date, to?: Date }
}
const FilterState = () => {
  const [limit, setLimit] = useState(30)

  const [filters, setFilters] = useState<Filters>({
    statusIn: ['pending'],
    wordCount: undefined,
    nbReports: { from: 0 }
  })

  const updateFilters = (payload) => {
    setFilters(filters => ({ ...filters, ...payload }))
  }

  return { filters, updateFilters, limit, setLimit }
}

export const FilterStateContainer = createContainer(FilterState)

export const FilterStateProvider = props => <FilterStateContainer.Provider {...props} />

export const useFilterState = () => FilterStateContainer.useContainer()