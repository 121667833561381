import styled from 'styled-components'
import { colors } from '../../theme'

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  color: #fff;
`

export const Pages = styled.div`
  color: ${colors.darkPurple};
  font-size: 17px;
  font-weight: bold;
  padding-left: 10px;
`

export const LimitInput = styled.input`
  border: none;
  width: 50px;
  height: 40px;
  font-size: 20px;
  margin-right: 6px;
  text-align: center;
  box-shadow: 0px 0px 5px 0 rgba(0,0,0,0.4);
  border-radius: 3px;
`
