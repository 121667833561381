import React from "react"

import CommentsFeed from "../components/CommentsFeed";

const CommentsPage = () => {
  if (typeof window === "undefined") return null

  return <CommentsFeed />
}

export default CommentsPage
