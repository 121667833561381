import styled from 'styled-components'

export const Wrapper = styled.div`
  padding-bottom: 10px;
  margin-top: 10px;
`

export const Editor = styled.div`
  height: 100px;
  color: #000;
  background-color: #fff;
  font-size: 16px;
`