import { Calendar, CheckBox, TextInput } from 'grommet'
import React, { useEffect, useState } from 'react'
import { FormattedDate } from "react-intl"
import styled from 'styled-components'
import { colors } from '../../../theme'
import { useFilterState } from '../state/FiltersState'


const Wrapper = styled.div`
  margin-bottom: 15px;
  display: flex;
  flex-wrap: wrap;
  > * { margin-right: 25px }
`

const Status = styled.div`
  > :first-child {
    font-size: 20px;
    font-weight: bold;
    line-height: 20px;
    margin-bottom: 10px;
  }
  > div {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    > :first-child { margin-right: 8px; }
  }
`

const SelectedDate = styled.div`
  position: relative;
  height: 42px;
  display: flex;
  align-items: center;
  background-color: ${colors.indigo};
  border-radius: 8px;
  margin-top: 10px;
  padding: 0 12px;
  color: #fff;
  > :first-child {
    cursor: pointer;
    width: 100%;
  }
`

const StyledCalendar = styled(Calendar)`
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  color: #000;
  padding: 15px;
  width: 100%;
  width: auto;
  transform: translateY(50px);
`

const WordFilters = styled.div`
  > * { width: 70px; }
`

const Filters = () => {
  const { filters, updateFilters } = useFilterState()
  const [calendarDisplayed, setCalendarDisplayed] = useState(false)
  const [date, setDate] = useState(new Date('2020-03-13').toString())

  useEffect(() => {
    setCalendarDisplayed(false)
  }, [date])

  const toggleStatus = (value) => {
    let newStatus: string[] = []
    if (filters.statusIn.indexOf(value) >= 0) {
      newStatus = filters.statusIn.filter(d => d !== value)
    } else {
      newStatus = [...filters.statusIn, value]
    }
    updateFilters({ statusIn: newStatus })
  }

  const toggleReports = () => {
    if (filters.nbReports?.from && filters.nbReports.from >= 1) {
      updateFilters({ nbReports: { from: 0 }})
    } else {
      updateFilters({ nbReports: { from: 1 }})
    }
  }

  return <Wrapper>
  <Status>
    <div>Status</div>
    <div><CheckBox
      checked={filters.statusIn.indexOf('published') >= 0}
      onChange={() => toggleStatus('published')}
    /> Published</div>
    <div><CheckBox
      checked={filters.statusIn.indexOf('pending') >= 0}
      onChange={() => toggleStatus('pending')}
    /> Pending</div>
    <div><CheckBox
      checked={filters.statusIn.indexOf('rejected') >= 0}
      onChange={() => toggleStatus('rejected')}
    /> Flagged</div>
    <div><CheckBox
      checked={filters.statusIn.indexOf('archived') >= 0}
      onChange={() => toggleStatus('archived')}
    /> Archived</div>
  </Status>
  <Status>
    <div>Words</div>
    <div><CheckBox checked={filters.wordCount && true} onChange={(e) =>
      updateFilters({ wordCount: e.target.checked ? { from: 0, to: 30 } : undefined })
    } /> Enabled</div>
    { filters.wordCount && <WordFilters>
      <TextInput defaultValue={filters.wordCount.from} onBlur={e => updateFilters({ wordCount: { ...filters.wordCount, from: parseInt(e.target.value, 10)}})} />
      <TextInput defaultValue={filters.wordCount.to} onBlur={e => updateFilters({ wordCount: { ...filters.wordCount, to: parseInt(e.target.value, 10)}})} />
    </WordFilters> }
  </Status>
  <Status>
  <SelectedDate>
    { /* eslint-disable-next-line */ }
        <div onClick={() => setCalendarDisplayed(!calendarDisplayed)}>
          <FormattedDate value={date} />
        </div>
        {calendarDisplayed && (
          <StyledCalendar
            size="small"
            date={date}
            onSelect={date => setDate(date)}
          />
        )}
      </SelectedDate>
  </Status>
  <Status>
    <div><CheckBox
      checked={Boolean(filters.nbReports?.from && filters.nbReports.from >= 1)}
      onChange={() => toggleReports()}
    /> Reported</div>
  </Status>
</Wrapper>
}

export default Filters

