import { Link } from "gatsby";
import { Box } from "grommet";
import 'twin.macro'
import { MoreVertical, Next } from "grommet-icons";
import { Checkmark, Link as LinkIcon, IconButton } from 'nzk-react-components';
import React, { useState } from "react";
import { FormattedDate } from "react-intl";
import { Avatar } from "nzk-react-components";
import User from "./components/User";
import * as s from './index.styles';

interface CommentProps {
  _id: string
  text: string
  children?: JSX.Element
  writing?: {
    _id: string,
    title: string
  },
  user?: {
    _id: string,
    username?: string,
    name?: string,
    avatar?: {
      url: string
    }
  },
  zookeeper?: {
    _id: string,
    username?: string,
    name?: string,
    avatar?: {
      url: string
    }
  },
  reports?: {
    user: {
      _id: string,
      username: string,
      avatar: {
        url: string,
      }
    },
    reason: string,
    reportedAt: Date
  }[],
  status?: 'pending' | 'rejected' | 'published' | 'archived'
  createdAt?: number,
  actions?: JSX.Element[],
  editButton?: JSX.Element
  bottomButtons?: JSX.Element[]
}

const Comment = (props: CommentProps) => {
  const [copied, setCopied] = useState(false)

  const onCopyId = () => {
    navigator.clipboard.writeText(props._id)
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 3000)
  }

  return (
    <s.Wrapper>
      <Box background="#fff" round="small" elevation="medium">
        <s.Header>
          <s.Users>
            <User {...props.user} />
            <Next />
            <User {...props.zookeeper} />
          </s.Users>
          { props.status === 'pending' && <s.Pending>Pending</s.Pending> }
          { props.status === 'published' && <s.Published>Published</s.Published> }
          { props.status === 'rejected' && <s.Flagged>Flagged</s.Flagged> }
          { props.status === 'archived' && <s.Archived>Archived</s.Archived> }
          { props._id && <s.CommentId>
            {props._id}
          </s.CommentId>}
          { props.writing && <s.WritingTitle>
            <Link to={`/writing/${props.writing._id}`}><div>{ props.writing && props.writing.title }</div></Link>
          </s.WritingTitle> }
          { props.createdAt && <s.Timestamp><FormattedDate value={props.createdAt} /></s.Timestamp> }
          { props.editButton ? <div tw='ml-3'>{props.editButton}</div> : null }
          { props.actions && <s.StyledDropButton
            dropAlign={{ top: 'bottom', right: 'right' }}
            dropContent={
              <Box pad="small" background="light-2">
                <s.Actions>
                  { props.actions }
                  <IconButton key='edit' size='x-small' theme={copied ? 'confirm' : 'primary'} icon={copied ? <Checkmark /> : <LinkIcon />}  onClick={() => onCopyId()}>
                    { copied ? 'Copied' : 'Copy ID' }
                  </IconButton>
                </s.Actions>
              </Box>
            }
          ><s.ActionsTrigger><MoreVertical color='#fff' /></s.ActionsTrigger>
          </s.StyledDropButton> }
        </s.Header>
        <s.Content pad="small">
          {props.children || <div dangerouslySetInnerHTML={{ __html: props.text ? props.text.replace(/\n/g, '<br />') : '' }} /> }
        </s.Content>
        { props.reports && props.reports.length > 0 && <s.Reports>
          <div>Reports ({ props.reports.length })</div>
          { props.reports.map(r => <s.Report key={r.user._id}>
            <div>
              { /* @ts-ignore */ }
              <Avatar user={r.user} size='40px' />
              <div>{r.user.username}</div>
            </div>
            <div>{r.reason}</div>
            <div>
              <FormattedDate value={r.reportedAt} />
            </div>
          </s.Report>) }
        </s.Reports>}
        { props.bottomButtons && <Box>
          <s.BottomButtons>
            { props.bottomButtons }
          </s.BottomButtons>
        </Box> }
      </Box>
    </s.Wrapper>
  )
}

Comment.defaultProps = {
}

export default Comment
