import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Comment from '../../../../../UI/Comment';
import { Editor } from './index.styles'
import Button from '../../../../../UI/Button';
import { colors } from '../../../../../../theme';

let Quill
if (typeof window !== 'undefined') {
  Quill = require('quill')
}

const CommentEditor = (props) => {
  const [editor, setEditor] = useState()
  
  useEffect(() => {
    if (typeof window === 'undefined') return
    let quillEditor = new Quill(
      `#comment-editor-${props._id}`,
      {
        theme: "snow",
        modules: {
          toolbar: [
            [{ header: [1, 2, false] }],
            ["bold", "italic", "underline"],
            ["link"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ color: [] }, { background: [] }],
          ],
        },
      },
      []
    )
    setEditor(quillEditor)
    return function cleanup() {
      document.querySelector(".ql-toolbar") &&
      document.querySelector(".ql-toolbar").remove()
    }
  }, [props.text, props._id])

  const value = () => {
    if (!editor) return null
    let value = editor.container.firstElementChild.innerHTML
    if (value === '<p><br></p>') {
      value = null
    }
    return value
  }

  return <Comment {...props} bottomButtons={[
    <Button key={`cancel-${props._id}`} bgColor={colors.darkBlue} onClick={props.onCancel}>Cancel</Button>,
    <Button key={`save-${props._id}`} bgColor={colors.indigo} onClick={() => props.onSave(value())}>Save</Button>,
  ]}>
    <Editor id={`comment-editor-${props._id}`} dangerouslySetInnerHTML={{ __html: props.text ? props.text.replace(/\n/g, '<br />') : "" }} />
  </Comment>
}

CommentEditor.propTypes = {
  _id: PropTypes.string,
  text: PropTypes.string,
  onCancel: PropTypes.func,
  onSave: PropTypes.func
}

CommentEditor.defaultProps = {
  onCancel: () => {},
  onSave: (text) => {}
}

export default CommentEditor
