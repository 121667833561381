import { useApolloClient } from "@apollo/client"
import { useEffect, useState } from "react"

const useQuery = ({ query, variables, fetchPolicy, formatResult = d => d }, effects = []) => {
  const client = useApolloClient()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState()
  const [errors, setErrors] = useState([])

  const execute = async () => {
    setLoading(true)
    setData(null)
    const { data, errors } = await client.query({
      query,
      variables,
      fetchPolicy
    })
    setData(formatResult(data))
    if (errors) {
      setErrors([...errors])
    }
    setLoading(false)
  }

  useEffect(() => {
    execute()
  }, effects)

  return [loading, data, errors, execute]
}

export default useQuery