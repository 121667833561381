import { Box, DropButton } from "grommet";
import styled from "styled-components";
import { colors } from "../../../theme";

export const Wrapper = styled.div`
  margin-bottom: 30px;
  > :first-child {
    overflow: hidden;
  }
`

export const Header = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px;
  border-bottom: 2px solid ${colors.lightGray};
  background-color: #011627;
  overflow: hidden;
  @media (max-width: 420px) {
    flex-direction: column;
  }
`

export const CommentId = styled.div`
  color: #fff;
  margin-left: 20px;
`

export const Users = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  > * { margin-right: 10px; }
  > :last-child { margin-right: 0; }
  @media (max-width: 420px) {
    margin-bottom: 15px;
  }
`

export const StyledDropButton = styled(DropButton)`
  @media (max-width: 420px) {
    position: absolute;
    top: 10px;
    right: 10px;
  }
`

export const ActionsTrigger = styled.div`
  height: 45px;
  width: 45px;
  display: flex;
  margin-left: auto;
  align-items: center;
  justify-content: center;
`

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  > * {
    margin-bottom: 10px;
    padding: 4px 0;
  }
  > :last-child {
    margin-bottom: 0px;
  }
`

export const BottomButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid ${colors.lightGray};
  padding: 8px;
  > * {
    margin-right: 10px;
  }
  > :last-child {
    margin-right: 0px;
  }
`

export const Content = styled(Box)`
  max-height: 250px;
  overflow-y: auto;
`

export const Timestamp = styled.div`
  color: #fff;
  margin-left: auto;
`

export const WritingTitle = styled.div`
  margin-left: auto;
  font-size: 14px;
  color: ${colors.indigo};
  font-weight: 500;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
  justify-content: flex-end;
  max-width: 300px;
  flex: 1;
  color: #fff;
  white-space: nowrap;
`

export const Status = styled.div`
  color: #fff;
  padding: 6px 12px;
  margin-left: 20px;
  border-radius: 8px;
  font-weight: bold;
  text-transform: uppercase;
  @media (max-width: 420px) {
    margin-bottom: 15px;
  }
`

export const Pending = styled(Status)`
  background-color: #D9724E;
`

export const Published = styled(Status)`
  background-color: #71D36E;
`

export const Flagged = styled(Status)`
  background-color: #E23B24;
`

export const Archived = styled(Status)`
  background-color: rgba(255,255,255,0.2);
`

export const Reports = styled.div`
  padding: 15px;
  > :first-child {
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 15px;
  }
  > * {
    border-bottom: 1px solid #333;
  }
  > :first-child, > :last-child {
    border-bottom: none;
  }
`

export const Report = styled.div`
  display: flex;
  align-items: center;
  > * { margin-right: 20px; }
  > :nth-child(2) {
    flex: 1;
    font-weight: bold;
  }
  > :first-child {
    display: flex;
    align-items: center;
    > :first-child {
      margin-right: 8px;
    }
  }
`
