import { Checkmark, Edit, Flag, Trash } from "grommet-icons"
import React, { useState } from "react"
import { colors } from "../../theme"
import CommentEditor from "../StudentInfo/components/Comments/components/CommentEditor"
import Comment from "../UI/Comment"
import Loader from "../UI/Loader"
import { IconButton as LegacyIconButton } from "../UI/Button"
import { Actions, Content, Wrapper, LimitInput, Pages } from "./index.styles"
import useCommentsFeed from "./useCommentsFeed"
import { FilterStateProvider, useFilterState } from "./state/FiltersState"
import Filters from "./components/Filters"
import { Button } from "grommet"
import styled from "styled-components"

const IconButton = styled(Button)`
  display: flex;
  align-items: center;
  > :first-child { margin-right: 8px; }
`

const CommentsFeed = props => {
  const {
    nbComments,
    nbCommentsLoading,
    comments,
    loading,
    publishAll,
    publishComment,
    rejectComment,
    updateComment,
    clearReports
  } = useCommentsFeed(true)
  const { limit, setLimit } = useFilterState()

  const [nbRequiredPress, setNbRequiredPress] = useState(3)
  const [editing, setEditing] = useState(null)

  const editComment = async (_id, text) => {
    await updateComment({ _id, text })
    setEditing()
  }

  const onPublishAllPressed = () => {
    if (nbRequiredPress <= 1) {
      setNbRequiredPress(3)
      return publishAll()
    }
    setNbRequiredPress(nbRequiredPress - 1)
  }

  return (
    <Wrapper>
      <Actions>
        <Pages>
          <LimitInput defaultValue={limit} onBlur={e => setLimit(parseInt(e.target.value) || 10)} />
          / {nbCommentsLoading ? "Loading..." : nbComments}
        </Pages>
        <LegacyIconButton bgColor={colors.green} onClick={onPublishAllPressed}>
          <Checkmark color="#fff" />
          <div>ALL ({nbRequiredPress})</div>
        </LegacyIconButton>
      </Actions>
      <Filters />
      <Content>
        {loading ? (
          <Loader />
        ) : (
          comments.map((comment, index) => {
            return editing === comment._id ? (
              <CommentEditor
                key={comment._id}
                {...comment}
                onSave={text => editComment(comment._id, text)}
                onCancel={() => setEditing()}
              />
            ) : (
              <Comment
                {...comment}
                key={comment._id}
                actions={[
                  <IconButton key='edit' onClick={() => setEditing(comment._id)}>
                    <Edit size='14px' />
                    Edit
                  </IconButton>,
                  comment.reports && comment.reports.length > 0 && <IconButton key='2' onClick={() => clearReports(comment)}>
                    <Trash size='14px' color={colors.red} />
                    Clear Reports
                  </IconButton>,
                  <IconButton key='publish' onClick={() => publishComment(comment)}>
                    <Checkmark size='14px' color={colors.green} />
                    Publish
                  </IconButton>,
                  <IconButton key='reject' onClick={() => rejectComment(comment)}>
                    <Flag size='14px' color={colors.red} />
                    Reject
                  </IconButton>
                ]}
                bottomButtons={[
                  <LegacyIconButton
                    key='publish-2'
                    bgColor={colors.green}
                    onClick={() => publishComment(comment)}
                  >
                    <Checkmark color="#fff" />
                  </LegacyIconButton>,
                  <LegacyIconButton
                    key='reject-2'
                    bgColor={colors.red}
                    onClick={() => rejectComment(comment)}
                  >
                    <Flag color="#fff" />
                  </LegacyIconButton>,
                ]}
              />
            )
          })
        )}
      </Content>
      <Actions>
        <LegacyIconButton bgColor={colors.green} onClick={onPublishAllPressed}>
          <Checkmark color="#fff" />
          <div>ALL ({nbRequiredPress})</div>
        </LegacyIconButton>
      </Actions>
    </Wrapper>
  )
}

CommentsFeed.propTypes = {}

CommentsFeed.defaultProps = {}

export default props => (
  <FilterStateProvider>
    <CommentsFeed {...props} />
  </FilterStateProvider>
)
